import * as React from 'react';
import { FC } from 'react';
import {
  DataGrid, deDE, GridColDef, GridToolbarContainer,
} from '@mui/x-data-grid';
import { FileDownloadOutlined } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { Buchung } from '../../dto/Buchung';
import { CustomNoRowsOverlay } from '../../components/datagrid/NoRows';
import { formatToStandardDate } from '../../utils/DateUtils';
import { capitalizeFirstLetter } from '../../utils/TextUtils';

const columns: GridColDef[] = [
  {
    flex: 1,
    headerAlign: 'center',
    align: 'right',
    field: 'bewegungsdatum',
    headerName: 'Bewegungsdatum',
    minWidth: 100,
    type: 'date',
    valueFormatter: (params) => {
      return params.value ? formatToStandardDate(params.value as Date) : '-';
    },
  },
  {
    flex: 1,
    field: 'rechnung',
    headerName: 'RechnungsNr',
    minWidth: 100,
    type: 'string',
  },
  {
    flex: 1,
    field: 'rechnungsdatum',
    headerName: 'Rechnungsdatum',
    minWidth: 100,
    type: 'date',
    valueFormatter: (params) => {
      return params.value ? formatToStandardDate(params.value as Date) : '-';
    },
  },
  {
    flex: 1,
    field: 'bewegungsArt',
    headerName: 'Bewegungsart',
    minWidth: 100,
    type: 'string',
    valueFormatter: (params) => {
      return capitalizeFirstLetter(params.value as string);
    },
  },
  {
    flex: 1,
    field: 'ccContainer',
    headerName: 'Container',
    headerAlign: 'center',
    align: 'center',
    minWidth: 70,
    type: 'string',
  },
  {
    flex: 1,
    field: 'ccBretter',
    headerName: 'Bretter',
    headerAlign: 'center',
    align: 'center',
    minWidth: 70,
    type: 'string',
  },
  {
    flex: 1,
    field: 'ccAufsetzer',
    headerName: 'Aufsetzer',
    headerAlign: 'center',
    align: 'center',
    minWidth: 70,
    type: 'string',
  },
  {
    flex: 1,
    field: 'hollandContainer',
    headerName: 'Holland',
    headerAlign: 'center',
    align: 'center',
    minWidth: 70,
    type: 'string',
  },
  {
    flex: 1,
    field: 'europaletten',
    headerName: 'Europaletten',
    headerAlign: 'center',
    align: 'center',
    minWidth: 70,
    type: 'string',
  },
];

export const BuchungenGrid: FC<{
    buchungen: Buchung[],
  download: () => void,
}> = ({
  buchungen,
  download,
}) => {
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          sx={{ p: 2 }}>
          <Button size={'small'} startIcon={<FileDownloadOutlined />} onClick={download}>Export</Button>
        </Box>
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{
      height: 800,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    }}>
      <div style={{
        minHeight: 500,
        width: '100%',
      }}>
        <DataGrid
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
          columns={columns}
          rows={buchungen}
          getRowId={(row) => row.id}
          components={{
            Toolbar: CustomToolbar,
            NoRowsOverlay: () => CustomNoRowsOverlay('Keine Buchungen für diesen Zeitraum'),
          }} />
      </div>
    </div>
  );
};
