import * as React from 'react';
import { FC } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  Link, matchPath, MemoryRouter, Route, Routes, useLocation,
} from 'react-router-dom';
import { StaticRouter } from 'react-router-dom/server';
import { Paper } from '@mui/material';
import { RechnungenOverview } from './pages/RechnungenOverview';
import { KundencodeContext } from './context/AppContext';
import { KontenOverview } from './pages/KontenOverview';
import { LogutBox } from './components/LogoutBox';
import { KontoDetails } from './pages/KontoDetails';
import { AvisierungenOverview } from './pages/AvisierungOverview';

function Router(props: any) {
  const { children } = props;
  if (typeof window === 'undefined') {
    return <StaticRouter location="/konten">{children}</StaticRouter>;
  }

  return (
    <MemoryRouter initialEntries={['/konten']} initialIndex={0}>
      {children}
    </MemoryRouter>
  );
}

Router.propTypes = {
  children: PropTypes.node,
};

function useRouteMatch(patterns: any) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

function CustomerTabs() {
  // You need to provide the routes in descendant order.
  // This means that if you have nested routes like:
  // users, users/new, users/edit.
  // Then the order should be ['users/add', 'users/edit', 'users'].
  const routeMatch = useRouteMatch(['/konten/*', '/rechnungen', '/avisierungen']);
  const currentTab = routeMatch?.pathnameBase;

  return (
    <Tabs value={currentTab} sx={{}}>
      <Tab label="Avisierungen" value="/avisierungen" to="/avisierungen" component={Link} />
      <Tab label="CC-Bewegung" value="/konten" to="/konten" component={Link} />
      <Tab label="Rechnungen" value="/rechnungen" to="/rechnungen" component={Link} />
    </Tabs>
  );
}

export const App: FC = () => (
  <div className="App">
    <Router>
      <Box sx={{ width: '100%' }}>
        <Paper
          sx={{
            p: 1,
            mb: 4,
            m: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          elevation={5}>
          <CustomerTabs />
          <LogutBox />
        </Paper>
        <KundencodeContext.Provider value={'testkunde'}>
          <Routes>
            <Route path="/rechnungen" element={<RechnungenOverview />} />
            <Route path="/konten" element={<KontenOverview />} />
            <Route path="/konten/:id" element={<KontoDetails />} />
            <Route path="/avisierungen" element={<AvisierungenOverview />} />
            <Route path="/" element={<KontenOverview />} />
          </Routes>
        </KundencodeContext.Provider>
      </Box>
    </Router>
  </div>
);
