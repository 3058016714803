import React, { FC, useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import { avisierungsApi } from '../api/AversierungsApi';
import { AvisierungenTable } from '../widgets/avisierung/AvisierungenTable';
import { Avisierung } from '../dto/Avisierung';
import { AvisierungsKonto } from '../dto/AvisierungsKonto';
import { CreateAvisierung } from '../widgets/avisierung/CreateAvisierung';

export const AvisierungenOverview: FC = () => {
  const [isLoading, setLoading] = useState(true);
  const [avisierungen, setAvisierungen] = useState<Avisierung[]>([]);
  const [avisierungsKonten, setAvisierungsKonten] = useState<AvisierungsKonto[]>([]);

  const loadAvisierungen = () => {
    setLoading(true);
    avisierungsApi.getAvisierungen()
      .then((response) => setAvisierungen(response))
      .then(() => setLoading(false));
  };

  const loadKonten = () => {
    avisierungsApi.getAvisierungsKonten()
      .then((value) => {
        setAvisierungsKonten(value);
      });
  };
  useEffect(() => {
    loadAvisierungen();
    loadKonten();
  }, []);

  return (
    <main>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Paper
          elevation={3}
          sx={{
            ml: 1, mr: 1, p: 1, width: '100%',
          }}>
          {avisierungsKonten.length > 0 && (
          <CreateAvisierung
            konten={avisierungsKonten}
            callback={loadAvisierungen} />
          )}
          <AvisierungenTable avisierungen={avisierungen} isLoading={isLoading} />
        </Paper>
      </Box>
    </main>
  );
};
