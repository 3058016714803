import React, { useCallback, useState } from 'react';
import { SnackbarOrigin } from '@mui/material/Snackbar/Snackbar';
import { Alert, AlertColor } from '@mui/material';

export const useSnackbar = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string | undefined>();
  const [severity, setSeverity] = useState<AlertColor | undefined>();

  const handleOpen = (msg: string, msgSeverity: AlertColor) => {
    setOpen(true);
    setMessage(msg);
    setSeverity(msgSeverity);
  };

  const onAlertBtnClose = useCallback(() => {
    setOpen(false);
    setMessage(undefined);
  }, [setOpen]);

  return {
    autoHideDuration: 5000,
    open,
    openSnackbar: handleOpen,
    onClose: onAlertBtnClose,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    } as SnackbarOrigin,
    ContentProps: {
      'aria-describedby': 'message-id',
    },
    children:
  <Alert
    severity={severity}
    variant="filled"
    sx={{ width: 300 }}>
    {message}
  </Alert>,
  };
};
