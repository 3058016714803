import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { StyledEngineProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { App } from './App';
import UserService from './utils/UserService';
import HttpService from './utils/HttpService';

Sentry.init({
  dsn: 'https://f01cd1ede54e48d8a41f11c809ddbea6@o4504173742587904.ingest.sentry.io/4504207949430784',
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const app = () => {
  ReactDOM.render(
    <StyledEngineProvider injectFirst>
      <App />
    </StyledEngineProvider>,
    document.getElementById('root'),
  );
};

UserService.initKeycloak(app);
HttpService.configure();
