import React, { FC } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

export const DatePickerWithText: FC<
  {startDate: Date, updateSelectedDate: (date: Date) => void, headline: string, sx?: any,
      minDate?: Date}
  > = ({
    startDate, updateSelectedDate, headline, sx, minDate,
  }) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Datepicker"
          minDate={minDate}
          maxDate={new Date()}
          value={startDate}
          onChange={(newValue: Date | null) => {
            if (newValue !== null) {
              updateSelectedDate(newValue);
            }
          }}
          PopperProps={{ placement: 'bottom' }}
          renderInput={({
            inputRef,
            inputProps,
            InputProps,
          }: any) => (
            <Box sx={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', ...sx,
            }}>
              {InputProps?.endAdornment}
              <Typography
                ref={inputRef}
                {...inputProps}
                fontSize={'large'}
                sx={{
                  pl: 1,
                }}>
                {headline}
              </Typography>

            </Box>
          )}
            />
      </LocalizationProvider>
    );
  };
