import React, { FC, useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import { kontenApi } from '../api/KontenApi';
import { KontenTable } from '../widgets/konten/KontenTable';

export const KontenOverview: FC = () => {
  const [isLoading, setLoading] = useState(true);
  const [konten, setKonten] = useState<any[]>([]);

  useEffect(() => {
    setLoading(true);
    kontenApi.getKonten()
      .then((response) => setKonten(response))
      .then(() => setLoading(false));
  }, []);

  return (
    <main>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Paper
          elevation={3}
          sx={{
            ml: 1, mr: 1, p: 1, width: '100%',
          }}>
          <KontenTable konten={konten} isLoading={isLoading} />
        </Paper>
      </Box>
    </main>
  );
};
