import * as React from 'react';
import { FC } from 'react';
import { DataGrid, deDE } from '@mui/x-data-grid';
import { CustomNoRowsOverlay } from '../../components/datagrid/NoRows';
import { columns } from './AvisierungenGridColumns';
import { Avisierung } from '../../dto/Avisierung';

export const AvisierungenTable: FC<{ avisierungen: Avisierung[], isLoading: boolean }> = ({
  avisierungen, isLoading,
}) => {
  return (
    <div style={{
      height: '100%',
      width: '100%',
      display: 'flex',
    }}>
      <div style={{
        height: '100%', width: '100%',
      }}>
        <DataGrid
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
          loading={isLoading}
          density={'comfortable'}
          rows={avisierungen}
          columns={columns}
          getRowId={(row: any) => {
            const c = row.avisierungUuid;
            return c;
          }}
          pageSize={20}
          rowsPerPageOptions={[20]}
          autoHeight
          initialState={{
            sorting: {
              sortModel: [{ field: 'datum', sort: 'desc' }],
            },
          }}
          components={{
            NoRowsOverlay: () => CustomNoRowsOverlay('Keine Avisierungen vorhanden'),
          }} />
      </div>
    </div>
  );
};
