import * as React from 'react';
import { FC } from 'react';
import { Paper } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Kontoauszug } from '../../dto/Kontoauszug';
import { formatToStandardDate } from '../../utils/DateUtils';

export const KontoSaldoSummary: FC<{ kontoauszug: Kontoauszug}> = ({
  kontoauszug,
}) => {
  return (
    <Paper
      elevation={3}
      sx={{ height: '100%', p: 2 }}>
      <Box sx={{
        display: 'flex', alignItems: 'center', flexDirection: 'column',
      }}>
        <Typography>
          {kontoauszug.kontoname}
        </Typography>
        <Box>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Saldo</TableCell>
                  <TableCell align="right">Container</TableCell>
                  <TableCell align="right">Bretter</TableCell>
                  <TableCell align="right">Aufsetzer</TableCell>
                  <TableCell align="right">Holland</TableCell>
                  <TableCell align="right">Europaletten</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key={0}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                 >
                  <TableCell component="th" scope="row" align="left">
                    {formatToStandardDate(kontoauszug.jahresbeginnSaldo.datum)}
                  </TableCell>
                  <TableCell align="right">
                    {kontoauszug.jahresbeginnSaldo.ccContainer}
                  </TableCell>
                  <TableCell align="right">{kontoauszug.jahresbeginnSaldo.ccBretter}</TableCell>
                  <TableCell align="right">{kontoauszug.jahresbeginnSaldo.ccAufsetzer}</TableCell>
                  <TableCell align="right">{kontoauszug.jahresbeginnSaldo.hollandContainer}</TableCell>
                  <TableCell align="right">{kontoauszug.jahresbeginnSaldo.europaletten}</TableCell>
                </TableRow>
                <TableRow
                  key={1}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                  <TableCell component="th" scope="row" align="left">
                    {formatToStandardDate(kontoauszug.fromSaldo.datum)}
                  </TableCell>
                  <TableCell align="right">
                    {kontoauszug.fromSaldo.ccContainer}
                  </TableCell>
                  <TableCell align="right">{kontoauszug.fromSaldo.ccBretter}</TableCell>
                  <TableCell align="right">{kontoauszug.fromSaldo.ccAufsetzer}</TableCell>
                  <TableCell align="right">{kontoauszug.fromSaldo.hollandContainer}</TableCell>
                  <TableCell align="right">{kontoauszug.fromSaldo.europaletten}</TableCell>
                </TableRow>
                <TableRow
                  key={2}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                  <TableCell component="th" scope="row" align="left">
                    {formatToStandardDate(kontoauszug.toSaldo.datum)}
                  </TableCell>
                  <TableCell align="right">
                    {kontoauszug.toSaldo.ccContainer}
                  </TableCell>
                  <TableCell align="right">{kontoauszug.toSaldo.ccBretter}</TableCell>
                  <TableCell align="right">{kontoauszug.toSaldo.ccAufsetzer}</TableCell>
                  <TableCell align="right">{kontoauszug.toSaldo.hollandContainer}</TableCell>
                  <TableCell align="right">{kontoauszug.toSaldo.europaletten}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ width: '100%', display: 'flex', pb: 1 }}>
          <Typography flex={0.5} align={'center'}>
            {kontoauszug.positiverHinweis}
          </Typography>
          <Typography flex={0.5} align={'center'}>
            {kontoauszug.negativerHinweis}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};
