import * as React from 'react';
import { FC, useState } from 'react';
import Box from '@mui/material/Box';
import {
  Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Snackbar, TextField,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { de } from 'date-fns/locale';
import { avisierungsApi } from '../../api/AversierungsApi';
import { useSnackbar } from '../../hooks/useSnackbar';
import { AvisierungsKonto } from '../../dto/AvisierungsKonto';

export const CreateAvisierung: FC<{ konten: AvisierungsKonto[], callback: () => void }> = ({
  konten,
  callback,
}) => {
  const [konto, setKonto] = useState<AvisierungsKonto>(konten[0]);
  const [date, setDate] = useState<Date>(new Date());
  const [container, setContainer] = useState<string>('0');
  const [bretter, setBretter] = useState<string>('0');
  const [aufsetzer, setAufsetzer] = useState<string>('0');
  const [holland, setHolland] = useState<string>('0');
  const [euro, setEuro] = useState<string>('0');

  const { openSnackbar, ...rest } = useSnackbar();

  const avisierungAnlegen = () => {
    avisierungsApi.createAvisierung({
      ccContainer: Number(container),
      ccAufsetzer: Number(aufsetzer),
      ccBretter: Number(bretter),
      hollandContainer: Number(holland),
      datum: date,
      europaletten: Number(euro),
      kontoUuid: konto.kontoId,
    }).then(() => {
      setContainer('0');
      setDate(new Date());
      setAufsetzer('0');
      setBretter('0');
      setHolland('0');
      setEuro('0');
      callback();
      openSnackbar('Avisierung erfolgreich angelegt!', 'success');
    }).catch(() => {
      openSnackbar('Avisierung konnte nicht angelegt werden!', 'error');
    });
  };

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      alignItems: 'center',
      mb: 2,
    }}>

      <Snackbar {...rest} />
      <FormControl sx={{ width: 300, mr: 2 }}>
        <InputLabel id="konto-label">Konto</InputLabel>
        <Select
          labelId="konto-label"
          id="konto"
          value={konto}
          onChange={(event) => {
            setKonto(event.target.value as AvisierungsKonto);
          }}
          input={<OutlinedInput label="Konto" />}>
          {konten.map((k: AvisierungsKonto) => (
            <MenuItem
              key={k.kontoId}
              value={k as any}>
              {k.kontoname}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
        <DatePicker
          label="Datum"
          value={date}
          minDate={new Date()}
          sx={{ maxWidth: 150, mr: 2 }}
          onChange={(newValue) => {
            if (newValue) {
              setDate(newValue);
            }
          }}
                />
      </LocalizationProvider>
      <TextField
        id="ccContainer"
        label="Container"
        variant="outlined"
        type="number"
        onFocus={(event) => {
          event.target.select();
        }}
        value={container}
        onChange={(event) => setContainer(event.target.value)}
        sx={{ maxWidth: 150, mr: 2 }} />
      <TextField
        id="ccBretter"
        label="Bretter"
        variant="outlined"
        type="number"
        onFocus={(event) => {
          event.target.select();
        }}
        value={bretter}
        onChange={(event) => setBretter(event.target.value)}
        sx={{ maxWidth: 150, mr: 2 }} />
      <TextField
        id="ccAufsetzer"
        label="Aufsetzer"
        variant="outlined"
        value={aufsetzer}
        type="number"
        onFocus={(event) => {
          event.target.select();
        }}
        onChange={(event) => setAufsetzer(event.target.value)}
        sx={{ maxWidth: 150, mr: 2 }} />
      <TextField
        id="ccHolland"
        label="Holland"
        variant="outlined"
        value={holland}
        type="number"
        onFocus={(event) => {
          event.target.select();
        }}
        onChange={(event) => setHolland(event.target.value)}
        sx={{ maxWidth: 150, mr: 2 }} />
      <TextField
        id="ccEuro"
        label="Europaletten"
        variant="outlined"
        value={euro}
        type="number"
        onFocus={(event) => {
          event.target.select();
        }}
        onChange={(event) => setEuro(event.target.value)}
        sx={{ maxWidth: 150, mr: 2 }} />
      <Button
        disabled={Number.isNaN(Number(container)) || Number.isNaN(Number(bretter))
                    || Number.isNaN(Number(aufsetzer)) || Number.isNaN(Number(holland))
                    || Number.isNaN(Number(euro))}
        variant={'contained'}
        onClick={avisierungAnlegen}>
        Avisierung anlegen
      </Button>
    </Box>
  );
};
