import { GridColDef } from '@mui/x-data-grid';

export const columns: GridColDef[] = [
  {
    flex: 2,
    headerAlign: 'center',
    align: 'left',
    field: 'kontoname',
    headerName: 'Kontoname',
    minWidth: 100,
    type: 'string',
  },
  {
    flex: 1,
    field: 'datum',
    headerName: 'Datum',
    headerAlign: 'center',
    align: 'right',
    minWidth: 120,
    type: 'date',
  },
  {
    flex: 1,
    headerAlign: 'center',
    field: 'ccContainer',
    headerName: 'Container',
    align: 'right',
    minWidth: 100,
    type: 'string',
  },
  {
    flex: 1,
    headerAlign: 'center',
    field: 'ccBretter',
    headerName: 'Bretter',
    align: 'right',
    minWidth: 100,
    type: 'string',
  },
  {
    flex: 1,
    headerAlign: 'center',
    align: 'right',
    field: 'ccAufsetzer',
    headerName: 'Aufsetzer',
    minWidth: 100,
  },
  {
    flex: 1,
    headerAlign: 'center',
    align: 'right',
    field: 'hollandContainer',
    headerName: 'Holland',
    minWidth: 100,
  },
  {
    flex: 1,
    headerAlign: 'center',
    align: 'right',
    field: 'europaletten',
    headerName: 'Europaletten',
    minWidth: 100,
  },

];
