import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import * as React from 'react';
import UserService from '../utils/UserService';

export const LogutBox = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography>{UserService.getUsername()}</Typography>
      <IconButton onClick={() => UserService.doLogout()}>
        <LogoutIcon />
      </IconButton>
    </Box>
  );
};
