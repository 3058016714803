import { RechnungsTyp } from '@nimeroo/kundenportal-backend-typescript-client';
import { rechungsApi } from '../../api/RechnungsApi';

export const handleClickPdfDownload = (rechnungsId: string,
  rechnungsTyp: RechnungsTyp): Promise<void> => {
  return rechungsApi.downloadRechungPdf(rechnungsId, rechnungsTyp)
    .then((res) => {
      const url = window.URL.createObjectURL(res.blob);
      const link: HTMLAnchorElement = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        res.name,
      );
      link.setAttribute('id', `${rechnungsId}_pdfDownload`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();
    });
};

export const handleClickExcelDownload = (rechnungsId: string): Promise<void> => {
  return rechungsApi.downloadRechungswarenExcel(rechnungsId)
    .then((res) => {
      const url = window.URL.createObjectURL(res.blob);
      const link: HTMLAnchorElement = document.createElement('a', {});
      link.setAttribute('href', url);
      link.setAttribute(
        'download',
        res.name,
      );

      link.setAttribute('id', `${rechnungsId}_excelDownload`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();
    });
};
