import {
  Configuration,
  RechnungDto,
  RechnungenControllerApiFactory,
  RechnungsTyp,
} from '@nimeroo/kundenportal-backend-typescript-client';
import { AxiosResponse } from 'axios';
import { Rechnung } from '../dto/Rechnung';
import HttpService from '../utils/HttpService';
import { DownloadFileDto } from '../dto/DownloadFileDto';

function toRechnung(dto: RechnungDto): Rechnung {
  return {
    rechnungsnummer: dto.rechnungsnummer,
    rechnungsId: dto.rechnungsId,
    rechnungsdatum: new Date(dto.rechnungsdatum),
    rechnungsbetrag: dto.rechnungsbetrag,
    rechnungsempfaenger: dto.rechnungsempfaenger,
    kundennummer: dto.kundennummer,
    ansprechpartner: dto?.ansprechpartner,
    ort: dto.ort,
    rechnungsTyp: dto.rechnungsTyp,
  };
}

class RechnungsApi {
  rechnungsApi = RechnungenControllerApiFactory(new Configuration(),
    'https://api.kundenportal.db-blumen.de', HttpService.getAxiosClient());

  getRechnungen(from: string, to: string) {
    return this.rechnungsApi.getRechnungenByDate(from, to)
      .then((value) => value.data.map((d) => toRechnung(d)));
  }

  async downloadRechungPdf(rechungsId: string, rechnungsTyp: RechnungsTyp)
    : Promise<DownloadFileDto> {
    return this.rechnungsApi.getRechnungsPdf(rechungsId, rechnungsTyp, { responseType: 'blob' })
      .then((value: AxiosResponse) => {
        console.log('Headers', value);
        const filenameHeader = value.headers['content-disposition']
          ? value.headers['content-disposition'] : undefined;

        let filename: string;
        if (filenameHeader) {
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
          filename = filenameHeader?.toString()
            ?.replace('"', '');
          filename = filename?.replace('attachment; filename=', '')
            .replace('"', '');
        } else {
          filename = `${rechungsId}.pdf`;
        }

        console.log(filename);
        return {
          blob: value.data,
          name: filename,
        };
      });
  }

  downloadRechungswarenExcel(rechungsId: string): Promise<DownloadFileDto> {
    return this.rechnungsApi.getRechnungswarenExcel(rechungsId, { responseType: 'blob' })
      .then((value: AxiosResponse) => {
        const filenameHeader = value.headers['content-disposition']
          ? value.headers['content-disposition'] : undefined;

        let filename: string;
        if (filenameHeader) {
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
          filename = filenameHeader?.toString()
            ?.replace('"', '');
          filename = filename?.replace('attachment; filename=', '')
            .replace('"', '');
        } else {
          filename = `${rechungsId}.xlsx`;
        }

        console.log(filename);
        return {
          blob: value.data,
          name: filename,
        };
      });
  }
}

export const rechungsApi = new RechnungsApi();
