export const dateToIso = (date: Date) => {
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const day = `${date.getDate()}`.padStart(2, '0');
  return `${date.getFullYear()}-${month}-${day}`;
};

export function formatToStandardDate(date: Date): string {
  return `${(`0${date.getDate()}`).slice(-2)}.${(`0${date.getMonth() + 1}`).slice(-2)}.${date.getFullYear()}`;
}

export function getInitialSystemStartDate(): Date {
  const date = new Date();
  date.setFullYear(2022, 0, 1);
  return date;
}
