import axios, { AxiosRequestConfig } from 'axios';
import UserService from './UserService';

const HttpMethods = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PUT: 'PUT',
};

const customAxios = axios.create();

const configure = () => {
  customAxios.interceptors.request.use((config) => {
    if (UserService.isLoggedIn()) {
      const cb = () => {
        const configWithToken: AxiosRequestConfig = {
          ...config,
          headers: {
            Authorization: `Bearer ${UserService.getToken()}`,
            'content-type': 'application/json',
          },
        };
        return Promise.resolve(configWithToken);
      };
      return UserService.updateToken(cb);
    }
  });
};

const getAxiosClient = () => customAxios;

const HttpService = {
  HttpMethods,
  configure,
  getAxiosClient,
};

export default HttpService;
