import Keycloak, { KeycloakTokenParsed } from 'keycloak-js';

const keycloak = Keycloak('/keycloak.json');

interface EnrichedToken extends KeycloakTokenParsed {
  preferred_username: string;
}

/**
 * Initializes Keycloak instance and calls the provided callback function
 * if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */

const doLogin = keycloak.login;

const initKeycloak = (onAuthenticatedCallback: any) => {
  keycloak.init({
    onLoad: 'login-required',
    checkLoginIframe: false,
  })
    .then((authenticated: boolean) => {
      if (authenticated) {
      // todo check role, if not got to login
        onAuthenticatedCallback();
      } else {
        doLogin();
      }
    });
};

const doLogout = keycloak.logout;

const getToken = () => keycloak.token;

const isLoggedIn = () => !!keycloak.token;

const updateToken = (successCallback: any) => keycloak.updateToken(5)
  .then(successCallback)
  .catch(doLogin);

const getUsername: (() => string) = () => (keycloak.tokenParsed as EnrichedToken)
  ?.preferred_username;

const hasRole = (roles: string[]) => roles.some((role) => keycloak.hasResourceRole(role, 'kundenportal'));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
};

export default UserService;
