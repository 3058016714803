import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import * as React from 'react';
import { formatToStandardDate } from '../../utils/DateUtils';
import { numberToEuro } from '../../utils/NumberUtils';
import { handleClickExcelDownload, handleClickPdfDownload } from './InvoiceDownload';

export const columns: GridColDef[] = [
  {
    flex: 1,
    headerAlign: 'center',
    align: 'right',
    field: 'rechnungsdatum',
    headerName: 'Datum',
    minWidth: 100,
    type: 'date',
    valueFormatter: (params) => {
      return params.value ? formatToStandardDate(params.value as Date) : '-';
    },
  },
  {
    flex: 1,
    field: 'rechnungsnummer',
    headerName: 'RechnungsNr.',
    minWidth: 120,
    type: 'string',
  },
  {
    flex: 1,
    field: 'kundennummer',
    headerName: 'KundenNr.',
    minWidth: 100,
    type: 'string',
  },
  {
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    field: 'rechnungsempfaenger',
    headerName: 'Kunde',
    minWidth: 300,
  },
  {
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    field: 'ort',
    headerName: 'Ort',
    minWidth: 150,
  },
  {
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    field: 'ansprechpartner',
    headerName: 'Rechnungsempfänger',
    minWidth: 140,
  },
  {
    flex: 1,
    field: 'rechnungsbetrag',
    headerName: 'Betrag',
    type: 'number',
    minWidth: 110,
    valueFormatter: (params: GridValueFormatterParams) => {
      return numberToEuro(params.value as number);
    },
  },
  {
    headerAlign: 'center',
    align: 'center',
    field: 'pdf',
    headerName: 'Rechnung (PDF)',
    sortable: false,
    minWidth: 140,
    renderCell: (params) => (
      <IconButton
        color="primary"
        aria-label="download"
        onClick={() => {
          handleClickPdfDownload(params.row.rechnungsId,
            params.row.rechnungsTyp);
        }}>
        <FileDownloadIcon />
      </IconButton>
    ),
  },
  {
    headerAlign: 'center',
    align: 'center',
    field: 'xlsx',
    headerName: 'Waren (Excel)',
    sortable: false,
    minWidth: 120,
    renderCell: (params) => (
      params.row.rechnungsTyp === 'NORMAL' && (
        <IconButton
          color="primary"
          aria-label="download"
          onClick={() => {
            handleClickExcelDownload(params.row.rechnungsId);
          }}>
          <FileDownloadIcon />
        </IconButton>
      )
    ),
  },
];
