import React, { FC, useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { kontenApi } from '../api/KontenApi';
import { dateToIso, getInitialSystemStartDate } from '../utils/DateUtils';
import { BuchungenGrid } from '../widgets/konten/BuchungenGrid';
import { Kontoauszug } from '../dto/Kontoauszug';
import { DatePickerWithText } from '../components/TypographyDatePicker';
import { KontoSaldoSummary } from '../widgets/konten/KontoSaldoSummary';

export const KontoDetails: FC = () => {
  const { id } = useParams();
  const [kontoauszug, setKontoauszug] = useState<Kontoauszug>();
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(),
    new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    if (id) {
      kontenApi.getKontoauszug(id, dateToIso(startDate), dateToIso(endDate)).then(setKontoauszug);
    }
  }, [id, startDate, endDate]);

  async function dowloadCsv() {
    const link = document.createElement('a');
    link.download = 'download.txt';
    kontenApi.getKontoauszugDownload(id!, dateToIso(startDate), dateToIso(endDate))
      .then((response) => response.data)
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `kontoauszug_${dateToIso(startDate)}_bis_${dateToIso(endDate)}_.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  return (
    <main>
      { kontoauszug && (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          pl: 2,
          pr: 2,
        }}>
          <Box sx={{ mt: 2 }}>
            {kontoauszug && (
              <KontoSaldoSummary kontoauszug={kontoauszug} />
            )}
          </Box>
          <Paper
            elevation={3}
            sx={{ height: '100%', width: '100%', mt: 2 }}>
            <Box sx={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', pb: 2, pt: 2,
            }}>
              <DatePickerWithText
                minDate={getInitialSystemStartDate()}
                startDate={startDate}
                updateSelectedDate={setStartDate}
                headline={`${startDate.toLocaleDateString()}`}
                sx={{ pr: 2 }} />
              <Typography
                fontSize={'large'}
                align={'center'}
                sx={{ pr: 2 }}>
                -
              </Typography>
              <DatePickerWithText
                minDate={getInitialSystemStartDate()}
                startDate={endDate}
                updateSelectedDate={setEndDate}
                headline={`${endDate.toLocaleDateString()}`} />
            </Box>
            <BuchungenGrid buchungen={kontoauszug.buchungen} download={() => dowloadCsv()} />
          </Paper>
        </Box>
      )}
    </main>
  );
};
