import React, { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { DatePickerWithText } from '../components/TypographyDatePicker';
import { rechungsApi } from '../api/RechnungsApi';
import { RechnungenGrid } from '../widgets/rechnung/RechnungenGrid';
import { Rechnung } from '../dto/Rechnung';
import { dateToIso } from '../utils/DateUtils';

export const RechnungenOverview: FC = () => {
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(),
    new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());
  const [rechnungen, setRechnungen] = useState<Rechnung[]>([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (startDate <= endDate) {
      setLoading(true);
      rechungsApi.getRechnungen(dateToIso(startDate), dateToIso(endDate))
        .then(setRechnungen)
        .then(() => setLoading(false));
    }
  }, [startDate, endDate]);

  return (
    <main>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Paper
          elevation={3}
          sx={{
            ml: 1, mr: 1, pb: 1, pr: 2, pl: 2, width: '100%',
          }}>
          <Box sx={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', pb: 2, pt: 2,
          }}>
            <DatePickerWithText
              startDate={startDate}
              updateSelectedDate={setStartDate}
              headline={`${startDate.toLocaleDateString()}`}
              sx={{ pr: 2 }} />
            <Typography
              fontSize={'large'}
              align={'center'}
              sx={{ pr: 2 }}>
              -
            </Typography>
            <DatePickerWithText
              startDate={endDate}
              updateSelectedDate={setEndDate}
              headline={`${endDate.toLocaleDateString()}`} />
          </Box>
          <RechnungenGrid rechnungen={rechnungen} isLoading={isLoading} />
        </Paper>
      </Box>
    </main>
  );
};
