import * as React from 'react';
import { FC } from 'react';
import { DataGrid, deDE } from '@mui/x-data-grid';
import { Konto } from '../../dto/Konto';
import { CustomNoRowsOverlay } from '../../components/datagrid/NoRows';
import { columns } from './kontenGridColumns';

export const KontenTable: FC<{ konten: Konto[], isLoading: boolean }> = ({
  konten, isLoading,
}) => {
  return (
    <div style={{
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    }}>
      <div style={{
        height: '100%', width: '100%',
      }}>
        <DataGrid
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
          loading={isLoading}
          density={'comfortable'}
          rows={konten}
          columns={columns}
          getRowId={(row) => row.kontoId}
          pageSize={20}
          rowsPerPageOptions={[20]}
          autoHeight
          initialState={{
            sorting: {
              sortModel: [{ field: 'kontoname', sort: 'asc' }],
            },
          }}
          components={{
            NoRowsOverlay: () => CustomNoRowsOverlay('Keine Konten vorhanden'),
          }}
                />
      </div>
    </div>
  );
};
