import * as React from 'react';
import { FC } from 'react';
import { DataGrid, deDE } from '@mui/x-data-grid';
import { Rechnung } from '../../dto/Rechnung';
import { CustomNoRowsOverlay } from '../../components/datagrid/NoRows';
import { columns } from './RechnungenGridColumns';

export const RechnungenGrid: FC<{ rechnungen: Rechnung[], isLoading: boolean }> = ({
  rechnungen,
  isLoading,
}) => {
  return (
    <div style={{
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    }}>
      <div style={{
        height: '100%', width: '100%',
      }}>
        <DataGrid
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
          loading={isLoading}
          density={'comfortable'}
          rows={rechnungen}
          columns={columns}
          getRowId={(row) => row.rechnungsId}
          pageSize={20}
          rowsPerPageOptions={[20]}
          autoHeight
          components={{
            NoRowsOverlay: () => CustomNoRowsOverlay('Keine Rechnungen für diesen Zeitraum'),
          }}
                />
      </div>
    </div>
  );
};
