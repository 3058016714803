import {
  AvisierungControllerApi,
  AvisierungDto,
  AvisierungsKontoDto,
  Configuration,
  NeueAvisierungDto,
} from '@nimeroo/kundenportal-backend-typescript-client';
import HttpService from '../utils/HttpService';
import { Avisierung, NeueAvisierung } from '../dto/Avisierung';
import { AvisierungsKonto } from '../dto/AvisierungsKonto';

export const dateToIso = (date: Date) => {
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const day = `${date.getDate()}`.padStart(2, '0');
  return `${date.getFullYear()}-${month}-${day}`;
};

function mapToAvisierungen(avisierungen: AvisierungDto[]): Avisierung[] {
  return avisierungen.map(
    (value) => {
      return {
        ...value,
        datum: new Date(value.datum),
      };
    },
  );
}

function mapToNeuAvisierungDto(avisierung: NeueAvisierung): NeueAvisierungDto {
  return {
    ...avisierung,
    datum: dateToIso(avisierung.datum),
  };
}

function mapToAvisierungsKonto(konten: AvisierungsKontoDto[]): AvisierungsKonto[] {
  return konten.map(
    (value) => {
      return {
        kontoId: value.kontoUuid,
        kontoname: value.kontoname,
      };
    },
  );
}

class AvisierungsApi {
  avisierungsApi = new AvisierungControllerApi(new Configuration(),
    'https://api.kundenportal.db-blumen.de', HttpService.getAxiosClient());

  getAvisierungen(): Promise<Avisierung[]> {
    return this.avisierungsApi.getAvisierungen()
      .then((value) => mapToAvisierungen(value.data));
  }

  createAvisierung(avisierung: NeueAvisierung): Promise<any> {
    return this.avisierungsApi.createAvisierung(mapToNeuAvisierungDto(avisierung));
  }

  getAvisierungsKonten(): Promise<AvisierungsKonto[]> {
    return this.avisierungsApi.getAvisierungsKonten()
      .then((value) => mapToAvisierungsKonto(value.data));
  }
}

export const avisierungsApi = new AvisierungsApi();
